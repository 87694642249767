import React from 'react';
import { Row, Form, Col, Container, Alert } from 'react-bootstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import SvgTick from '../SVGs/Tick';
import SvgClose from '../SVGs/SvgClose';

import DropZone from '../components/DropZone';

const AlertNotification = ({ show, setShow, snackbarMessage }) => {
  React.useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, []);

  return (
    <Alert className="alertBox m-0" show={show} variant={snackbarMessage === 'Something went Wrong!!!!..' ? 'danger' : 'success'} onClose={() => setShow(false)}>
      <div className="d-flex col p-0 align-items-center">
        <SvgTick style={{ fill: '#fff', width: 25, height: 25 }} />
        <p className="m-0 pl-2" style={{ flex: 1 }}>
          {snackbarMessage}
        </p>
      </div>
      <SvgClose style={{ height: 20, width: 20, stroke: 'white', strokeWidth: 4 }} onClick={() => setShow(false)} />
    </Alert>
  );
};

const getAQuote = () => {
  return (
    <Layout noUpperFooter pageInfo={{ pageName: 'getaquote' }} title="Let’s start a journey together" description="We would love to hear from you and be a part of your journey by bringing our skills and passion to build digital products to help grow your business.">
      <SEO title="Contact Us" />
      <QuoteDetails />
      <GetQuoteFooter />
    </Layout>
  );
};

export default getAQuote;

const QuoteDetails = () => {
  const [radioCheckedProject, setRadioCheckedProject] = React.useState(false);
  const [radioCheckedSomething, setRadioCheckedSomething] = React.useState(false);
  const [radioCheckedYes, setRadioCheckedYes] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [validated, setValidated] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [dropZone, setDropZone] = React.useState();
  const [isloading, setIsloading] = React.useState(false);
  const [fileValidation, setFileValidation] = React.useState(false);

  const handleInputChange = (type, value) => {
    setInputValue({ ...inputValue, [type]: value });
    if (type === 'brief' && fileValidation && document.getElementsByClassName('filepicker') && document.getElementsByClassName('filepicker').length && document.getElementsByClassName('filepicker')[0].className.includes('error-upload')) {
      document.getElementsByClassName('filepicker')[0].classList.remove('error-upload');
      setFileValidation(false);
    }
  };

  const handleButtonOnClick = async () => {
    try {
      if (dropZone && dropZone.event) dropZone.event.processQueue();
      const formdata = new FormData();

      if (inputValue && inputValue.brief) formdata.append('file', inputValue && inputValue.brief, inputValue && inputValue.brief && inputValue.brief.name);

      formdata.append('email', inputValue && inputValue.email);
      formdata.append('name', inputValue && inputValue.name);
      formdata.append('companyName', inputValue && inputValue.companyName);
      formdata.append('phone', inputValue && inputValue.phone);
      formdata.append('message', inputValue && inputValue.message);
      formdata.append('talkToUsAbout', radioCheckedProject ? ' Your Project' : ' Something Else');
      if (radioCheckedProject === true) formdata.append('doYouHaveAProjectBrief ', radioCheckedYes ? 'Yes' : 'NO');
      if (radioCheckedYes && inputValue && !inputValue.brief) {
        setFileValidation(true);
        if (document.getElementsByClassName('filepicker') && document.getElementsByClassName('filepicker').length) document.getElementsByClassName('filepicker')[0].classList.add('error-upload');
        setIsloading(false);
        return;
      }
      setRadioCheckedProject(false);
      setRadioCheckedSomething(false);
      setRadioCheckedYes(false);
      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
      };
      const response = await fetch('https://us-central1-thpillar-219209.cloudfunctions.net/apiEndPoints/api/v1/quote/get', requestOptions);
      const json = await response.json();
      if (json.success) {
        setSnackbarMessage('Thank you for your enquiry');
        setInputValue('');
        setIsloading(false);
        setShow(true);
      } else {
        setSnackbarMessage('Something went Wrong!!!!..');
        setIsloading(false);
        setShow(true);
      }
    } catch (error) {
      setSnackbarMessage('Something went Wrong!!!!..');
      setIsloading(false);
      setShow(true);
      throw new Error(error);
    }
  };

  const handleSubmit = (event) => {
    const form = event.target;
    setIsloading(true);
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      if (document.getElementById('get-a-quote-form')) {
        document.getElementById('get-a-quote-form').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      setIsloading(false);
      setValidated(true);
    }
    if (form.checkValidity()) {
      handleButtonOnClick();
      setValidated(false);
    }
  };
  const dropZoneUpload = (dropzone) => {
    setDropZone({ event: dropzone });
  };

  return (
    <Container className="container-styles" id="get-a-quote-form">
      <h3 className="text-center mt-4">GET A QUOTE</h3>
      <p className="w-100 text-center" style={{ maxWidth: 550 }}>
        Be as descriptive as possible for us to understand more about the project and give you more information
      </p>

      <Form noValidate validated={validated} onSubmit={(event) => handleSubmit(event)} className="col" style={{ width: '100%', marginTop: '2rem' }}>
        <Form.Row>
          <Form.Group as={Col} sm={12} md={6} controlId="validationCustom03" style={{ position: 'relative', paddingRight: '0.45rem', paddingLeft: '0.45rem' }}>
            <Form.Label className="input-label">Name *</Form.Label>
            <Form.Control disabled={isloading} required type="text" name="name" value={inputValue && inputValue.name} onChange={(event) => handleInputChange('name', event.target.value)} placeholder="Your full name" />
            <Form.Control.Feedback type="invalid">Please fill out this field.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} sm={12} md={6} controlId="validationCustom03" style={{ position: 'relative' }}>
            <Form.Label className="input-label">Company name</Form.Label>
            <Form.Control disabled={isloading} type="text" name="companyName" value={inputValue && inputValue.companyName} onChange={(event) => handleInputChange('companyName', event.target.value)} placeholder="Your company name" />
            <Form.Control.Feedback type="invalid">Please fill out this field.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} sm={12} md={6} controlId="validationCustom03" style={{ position: 'relative', paddingRight: '0.45rem', paddingLeft: '0.45rem' }}>
            <Form.Label className="input-label">Email *</Form.Label>
            <Form.Control disabled={isloading} required type="email" name="email" value={inputValue && inputValue.email} onChange={(event) => handleInputChange('email', event.target.value)} placeholder="Your email address" />
            <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} sm={12} md={6} controlId="validationCustom03" style={{ position: 'relative' }}>
            <Form.Label className="input-label">Phone *</Form.Label>
            <Form.Control disabled={isloading} required type="number" name="phone" value={inputValue && inputValue.phone} onChange={(event) => handleInputChange('phone', event.target.value)} placeholder="Your phone number" />
            <Form.Control.Feedback type="invalid">Please fill out this field.</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Group>
          <Form.Row className="py-2">
            <Col lg={6} xs={12}>
              <Form.Label className="font-weight-bold">You would like to talk to us about?</Form.Label>
            </Col>
            <Col className="text-left " lg={6} xs={12}>
              <div
                className="d-block d-md-fex pl-1 py-2 py-md-0"
                style={{
                  jusflexDirection: 'row',
                }}
              >
                <span className="radio_space">
                  <input
                    style={{ display: 'none' }}
                    id="project"
                    type="radio"
                    name="project"
                    disabled={isloading}
                    required
                    value={inputValue && inputValue.talkToUsAbout}
                    onChange={(event) => {
                      handleInputChange('talkToUsAbout', event.target.value);
                      setRadioCheckedProject(true);
                      setRadioCheckedSomething(false);
                      setRadioCheckedYes(false);
                    }}
                  />
                  <label htmlFor="project" className="radio-label">
                    Your Project
                  </label>
                </span>

                <span className="radio_space">
                  <input
                    style={{ display: 'none' }}
                    id="something_else"
                    type="radio"
                    name="project"
                    disabled={isloading}
                    required
                    value={inputValue && inputValue.something_else}
                    onChange={() => {
                      setRadioCheckedProject(false);
                      setRadioCheckedSomething(true);
                      setRadioCheckedYes(false);
                    }}
                  />
                  <label htmlFor="something_else" className="radio-label">
                    Something else
                  </label>
                  <Form.Control.Feedback className="select-radio-button" type="invalid">
                    Please choose one option.
                  </Form.Control.Feedback>
                </span>
              </div>
            </Col>
          </Form.Row>
          {radioCheckedProject ? (
            <Form.Row className="py-2">
              <Col lg={6} xs={12}>
                <Form.Label className="font-weight-bold">Do you have a project brief?</Form.Label>
              </Col>

              <Col style={{ textAlign: 'left', paddingLeft: '4px' }} lg={6} xs={12}>
                <div
                  className="d-block d-md-fex pl-1 py-2 py-md-0"
                  style={{
                    jusflexDirection: 'row',
                  }}
                >
                  <span className="radio_space">
                    <input
                      style={{ display: 'none' }}
                      id="yes"
                      type="radio"
                      disabled={isloading}
                      name="talk-about"
                      value={inputValue && inputValue.yes}
                      onChange={() => {
                        setRadioCheckedYes(true);
                      }}
                    />
                    <label htmlFor="yes" className="radio-label">
                      Yes
                    </label>
                  </span>

                  <span className="radio_space">
                    <input
                      style={{ display: 'none' }}
                      id="no"
                      type="radio"
                      disabled={isloading}
                      name="talk-about"
                      value={inputValue && inputValue.no}
                      onChange={() => {
                        setRadioCheckedYes(false);
                        setRadioCheckedSomething(false);
                      }}
                    />
                    <label htmlFor="no" className="radio-label">
                      No
                    </label>
                  </span>
                </div>
              </Col>
              {!radioCheckedYes ? (
                <>
                  <Col className="col md-5 mt-4 " xs={12}>
                    <Form.Label className="mt-2 label-styles">{radioCheckedProject ? 'Briefly describe your project' : 'Your Message'}</Form.Label>
                  </Col>
                  <Col className="col md-5 mt-2 ">
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Control disabled={isloading} name="message" value={inputValue && inputValue.message} onChange={(event) => handleInputChange('message', event.target.value)} className="quotetextarea" as="textarea" style={{ height: '300px', backgroundImage: 'none' }} />
                    </Form.Group>
                  </Col>
                </>
              ) : null}
            </Form.Row>
          ) : null}
          {radioCheckedSomething ? (
            <Form.Row>
              <Col className="col md-5 mt-4 " xs={12}>
                <Form.Label className="mt-2 label-styles">{radioCheckedProject ? 'Briefly describe your project' : 'Your Message'}</Form.Label>
              </Col>
              <Col className="col md-5 mt-2 ">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Control disabled={isloading} name="message" value={inputValue && inputValue.message} onChange={(event) => handleInputChange('message', event.target.value)} className="quotetextarea" as="textarea" style={{ height: '300px', backgroundImage: 'none' }} />
                </Form.Group>
              </Col>
            </Form.Row>
          ) : null}
        </Form.Group>

        <div className="my-4">
          {radioCheckedYes ? (
            <>
              <div className="position-relative">
                <DropZone fileValidation={fileValidation} acceptImageFilesToo handleOnchange={handleInputChange} dropZoneUpload={dropZoneUpload} />
                {fileValidation ? (
                  <div className="position-absolute mb-0 invalid-feedback d-flex" style={{ bottom: '-1.5rem' }}>
                    Please upload a file
                  </div>
                ) : null}
              </div>
              <Form.Row>
                <Col className="col md-5 mt-4 " xs={12}>
                  <Form.Label className="mt-2 label-styles">{radioCheckedProject ? 'Briefly describe your project' : 'Your Message'}</Form.Label>
                </Col>
                <Col className="col md-5 mt-2 ">
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control disabled={isloading} name="message" value={inputValue && inputValue.message} onChange={(event) => handleInputChange('message', event.target.value)} className="quotetextarea" as="textarea" style={{ height: '300px', backgroundImage: 'none' }} />
                  </Form.Group>
                </Col>
              </Form.Row>
            </>
          ) : null}
        </div>
        <Row className="d-flex justify-content-center align-items-center flex-column mb-5 ">
          <button type="submit" className={!isloading ? 'custom-button' : 'btn-disabled'}>
            {isloading ? 'SENDING...' : 'GET QUOTE'}
          </button>
        </Row>
      </Form>
      {show ? <AlertNotification show={show} setShow={setShow} snackbarMessage={snackbarMessage} /> : null}
    </Container>
  );
};

const GetQuoteFooter = () => {
  return (
    <div className="w-100 py-5" style={{ backgroundColor: 'black' }}>
      <Container>
        <Row className="p-4 p-md-0 d-flex justify-content-between text-white">
          <div>
            <h5 className="font-weight-normal mb-3" style={{ opacity: 0.75 }}>
              Headquarters
            </h5>
            <p className="font-weight-bold mb-1">Office #7C, BCG Towers,</p>
            <p className="font-weight-bold mb-1">Opposite CSEZ, Kakkanad,</p>
            <p className="font-weight-bold"> Cochin 682037</p>
            <p className="font-weight-bold mb-1">+91 9847032301</p>
          </div>
          <div>
            <h5 className="mb-3" style={{ opacity: 0.75 }}>
              For new project enquiries
            </h5>
            <p className="font-weight-bold mb-5">projects@7thpillar.com</p>
            <h5 className="mb-3" style={{ opacity: 0.75 }}>
              For career enquiries
            </h5>
            <p className="font-weight-bold">jobs@7thpillar.com</p>
          </div>
        </Row>
      </Container>
    </div>
  );
};
